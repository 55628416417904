@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  
}

body{
    font-style: italic;
    font-weight: bold;
}